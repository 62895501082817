// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-career-js": () => import("./../../../src/pages/about/career.js" /* webpackChunkName: "component---src-pages-about-career-js" */),
  "component---src-pages-about-contact-us-js": () => import("./../../../src/pages/about/contact-us.js" /* webpackChunkName: "component---src-pages-about-contact-us-js" */),
  "component---src-pages-about-info-submitted-js": () => import("./../../../src/pages/about/info-submitted.js" /* webpackChunkName: "component---src-pages-about-info-submitted-js" */),
  "component---src-pages-about-press-js": () => import("./../../../src/pages/about/press.js" /* webpackChunkName: "component---src-pages-about-press-js" */),
  "component---src-pages-about-who-we-are-js": () => import("./../../../src/pages/about/who-we-are.js" /* webpackChunkName: "component---src-pages-about-who-we-are-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-learn-blog-js": () => import("./../../../src/pages/learn/blog.js" /* webpackChunkName: "component---src-pages-learn-blog-js" */),
  "component---src-pages-learn-how-we-invest-js": () => import("./../../../src/pages/learn/how-we-invest.js" /* webpackChunkName: "component---src-pages-learn-how-we-invest-js" */),
  "component---src-pages-learn-stories-js": () => import("./../../../src/pages/learn/stories.js" /* webpackChunkName: "component---src-pages-learn-stories-js" */),
  "component---src-pages-product-56-js": () => import("./../../../src/pages/product/56.js" /* webpackChunkName: "component---src-pages-product-56-js" */),
  "component---src-pages-product-aqumon-js": () => import("./../../../src/pages/product/aqumon.js" /* webpackChunkName: "component---src-pages-product-aqumon-js" */),
  "component---src-pages-product-asset-allocation-js": () => import("./../../../src/pages/product/asset-allocation.js" /* webpackChunkName: "component---src-pages-product-asset-allocation-js" */),
  "component---src-pages-product-bespoke-investors-appointment-js": () => import("./../../../src/pages/product/bespoke-investors/appointment.js" /* webpackChunkName: "component---src-pages-product-bespoke-investors-appointment-js" */),
  "component---src-pages-product-bespoke-investors-bespoke-js": () => import("./../../../src/pages/product/bespoke-investors/bespoke.js" /* webpackChunkName: "component---src-pages-product-bespoke-investors-bespoke-js" */),
  "component---src-pages-product-bespoke-investors-hk-overseas-js": () => import("./../../../src/pages/product/bespoke-investors/hk-overseas.js" /* webpackChunkName: "component---src-pages-product-bespoke-investors-hk-overseas-js" */),
  "component---src-pages-product-delete-smart-stock-js": () => import("./../../../src/pages/product/delete-smart-stock.js" /* webpackChunkName: "component---src-pages-product-delete-smart-stock-js" */),
  "component---src-pages-product-institutions-js": () => import("./../../../src/pages/product/institutions.js" /* webpackChunkName: "component---src-pages-product-institutions-js" */),
  "component---src-pages-product-stock-trading-js": () => import("./../../../src/pages/product/stock-trading.js" /* webpackChunkName: "component---src-pages-product-stock-trading-js" */),
  "component---src-pages-promotions-js": () => import("./../../../src/pages/promotions.js" /* webpackChunkName: "component---src-pages-promotions-js" */),
  "component---src-pages-resources-faq-js": () => import("./../../../src/pages/resources/faq.js" /* webpackChunkName: "component---src-pages-resources-faq-js" */),
  "component---src-pages-resources-fee-js": () => import("./../../../src/pages/resources/fee.js" /* webpackChunkName: "component---src-pages-resources-fee-js" */),
  "component---src-pages-resources-legal-js": () => import("./../../../src/pages/resources/legal.js" /* webpackChunkName: "component---src-pages-resources-legal-js" */),
  "component---src-pages-resources-security-js": () => import("./../../../src/pages/resources/security.js" /* webpackChunkName: "component---src-pages-resources-security-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blogPost.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-blog-post-redirect-js": () => import("./../../../src/templates/blogPostRedirect.js" /* webpackChunkName: "component---src-templates-blog-post-redirect-js" */),
  "component---src-templates-institutions-page-js": () => import("./../../../src/templates/institutionsPage.js" /* webpackChunkName: "component---src-templates-institutions-page-js" */),
  "component---src-templates-job-js": () => import("./../../../src/templates/job.js" /* webpackChunkName: "component---src-templates-job-js" */),
  "component---src-templates-old-blog-redirect-js": () => import("./../../../src/templates/oldBlogRedirect.js" /* webpackChunkName: "component---src-templates-old-blog-redirect-js" */),
  "component---src-templates-product-details-js": () => import("./../../../src/templates/product-details.js" /* webpackChunkName: "component---src-templates-product-details-js" */)
}

