module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"gatsby-starter-default","short_name":"AQUMON","start_url":"/","background_color":"#ffffff","theme_color":"#ffffff","display":"minimal-ui","icon":"src/images/icon/aqumon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"dec1ac11537fba4b4e3eb83dfa7d59bd"},
    },{
      plugin: require('../node_modules/gatsby-plugin-intl/gatsby-browser.js'),
      options: {"plugins":[],"path":"/jenkins-data/workspace/PRO-ENV/aqumon/web-home-v5--pro-k8s/src/intl","languages":["zh-cn","zh-hk","en"],"defaultLanguage":"en","redirect":true,"redirectComponent":"/jenkins-data/workspace/PRO-ENV/aqumon/web-home-v5--pro-k8s/src/components/Redirect/index.js"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["UA-83158928-1","AW-873881428","AW-780191873"]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
