import React from "react";
import { useIntl } from "gatsby-plugin-intl";
import SEO from "../SEO";

const Redirect = () => {
  const intl = useIntl();
  return <SEO title={`${intl.formatMessage({ id: "title" })}`} />;
};

export default Redirect;
