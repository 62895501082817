/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */
import('typeface-exo');
// in gastby-browser.js
exports.shouldUpdateScroll = ({
  routerProps: { location },
  getSavedScrollPosition,
}) => {
  console.log(location, 'shouldUpdateScroll 1111111');

  if (location.hash !== '#contactUs') {
    document.getElementById('___gatsby').scrollTop = 0;
  }
  // const { pathname } = location
  // list of routes for the scroll-to-top-hook

  return false;
};
